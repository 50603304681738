// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ecard-js": () => import("./../../../src/pages/ecard/[...].js" /* webpackChunkName: "component---src-pages-ecard-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-templates-casestudies-js": () => import("./../../../src/templates/casestudies.js" /* webpackChunkName: "component---src-templates-casestudies-js" */),
  "component---src-templates-casestudy-js": () => import("./../../../src/templates/casestudy.js" /* webpackChunkName: "component---src-templates-casestudy-js" */),
  "component---src-templates-filter-js": () => import("./../../../src/templates/filter.js" /* webpackChunkName: "component---src-templates-filter-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

